<template>
  <div class="main">
    <div class="topbar">
      <Tabs v-model="currentTabIndex"
            class="tabs">
        <TabPane v-for="item in tabArr"
                 :key="item.name"
                 :label="item.name"></TabPane>
      </Tabs>
      <div class="topbar-search">
        <div class="flex a-center j-between">
          <div class="flex a-center">
            <template v-if="currentTabIndex===0">
              <Select v-model="role"
                      clearable
                      placeholder="角色"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in roleArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <!-- <MyTreeSelect :value="account"
                            placeholder="用户账号"
                            readonly
                            clearable
                            :data="accountArr"
                            search
                            @onSelectChange="onChangeAccount"
                            style="width:160px;margin-right: 10px;">
              </MyTreeSelect> -->
              <Input v-model="account"
                     clearable
                     maxlength="30"
                     v-stringLimit
                     placeholder="用户账号"
                     class="m-r-10"
                     style="width:160px" />
              <Input v-model="name"
                     clearable
                     maxlength="30"
                     v-stringLimit
                     placeholder="姓名"
                     class="m-r-10"
                     style="width:160px" />
            </template>
            <template v-if="currentTabIndex===0">
              <Checkbox v-model="organizationAll">查询机构下所有</Checkbox>
            </template>
            <template v-if="currentTabIndex===1">
              <Input v-model="roleName"
                     clearable
                     maxlength="30"
                     v-stringLimit
                     placeholder="角色名称"
                     class="m-r-10"
                     style="width:160px" />
            </template>
          </div>
          <div class="flex">
            <Button type="primary"
                    class="m-l-10"
                    v-if="checkPermi(['/admin/userArchives/query'])"
                    @click.stop="onClickSearch">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10"
                    v-if="checkPermi(['/admin/userArchives/query'])"
                    @click.stop="onClickReset">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="flex a-center j-between">
        <template v-if="currentTabIndex===0">
          <div class="flex">
            <Button type="primary"
                    class="m-r-10"
                    v-if="checkPermi(['/admin/userArchives/add'])"
                    @click.stop="userVisible = true">
              <Icon custom="i-icon icon-tianjia1"
                    size="16"></Icon>
              添加用户
            </Button>
            <Button class="m-r-10"
                    v-if="checkPermi(['/admin/userArchives/add'])"
                    @click.stop="departmentVisible = true">
              <Icon custom="i-icon icon-tianjia1"
                    size="16"></Icon>
              添加机构
            </Button>
            <Dropdown @on-click="onClickDropdown"
                      v-if="checkPermi(['/admin/department/query'])">
              <Button class="m-r-10">
                <Icon custom="i-icon icon-xiugai1"
                      size="16"></Icon>
                机构操作
              </Button>
              <DropdownMenu slot="list">
                <DropdownItem name="detail"
                              v-if="checkPermi(['/admin/department/query'])">
                  <Button>
                    <Icon custom="i-icon icon-wuziqingkuang"
                          size="16"></Icon>
                    机构详情
                  </Button>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <!-- <Button class="m-r-10">
              <Icon custom="i-icon icon-shanchu1"
                    size="16"></Icon>
              批量删除
            </Button> -->
          </div>
        </template>
        <template v-if="currentTabIndex===1">
          <div class="flex">
            <Button type="primary"
                    class="m-r-10"
                    v-if="checkPermi(['/admin/userArchives/add'])"
                    @click.stop="roleVisible=true">
              <Icon custom="i-icon icon-tianjia1"
                    size="16"></Icon>
              添加
            </Button>
          </div>
        </template>
      </div>
      <div class="flex">
        <div class="tree noScroll"
             :style="{height:tableHeight+50+'px'}"
             v-if="currentTabIndex===0">
          <MyTree :data="departmentTreeArr"
                  style="padding:0 10px"
                  @on-select-change="onSelectChangeDepart"></MyTree>
        </div>
        <div class="table">
          <template v-if="currentTabIndex===0">
            <Table ref="table"
                   :width="tableWidth"
                   :height="tableHeight"
                   :columns="getColumns"
                   :data="data"
                   border
                   stripe
                   :loading="tableLoading"
                   :row-class-name="rowClassName"
                   @on-selection-change="onChangeSelect"
                   @on-sort-change="onChangeSort">
              <template slot-scope="{ row }"
                        slot="operation">
                <template v-if="currentTabIndex == 0">
                  <Button @click.stop="onClickEditUser(row)"
                          type="text"
                          v-hasPermi="['/admin/userArchives/edit']"
                          class="tableOperaBtn">修改</Button>
                  <Button @click.stop="onClickUserResetPwd(row)"
                          type="text"
                          v-hasPermi="['/admin/userArchives/edit']"
                          class="tableOperaBtn">重置密码</Button>
                  <Button @click.stop="onClickDeleteUser(row)"
                          type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/userArchives/delete']">删除</Button>
                </template>
              </template>
            </Table>
          </template>
          <template v-else>
            <Table ref="table"
                   :width="tableWidths"
                   :height="tableHeight"
                   :columns="getColumns"
                   :data="data"
                   border
                   stripe
                   :loading="tableLoading"
                   :row-class-name="rowClassName"
                   @on-selection-change="onChangeSelect"
                   @on-sort-change="onChangeSort">
              <template slot-scope="{ row }"
                        slot="operation">
                <template v-if="currentTabIndex===1 && row.id!= '00000000'">
                  <Button @click.stop="onClickEditRole(row)"
                          type="text"
                          v-hasPermi="['/admin/userArchives/edit']"
                          class="tableOperaBtn">修改</Button>
                  <Button @click.stop="onClickStatusRole(row)"
                          type="text"
                          v-hasPermi="['/admin/userArchives/edit']"
                          class="tableOperaBtn">{{row.status == 0 ? '停用' : '恢复'}}</Button>
                  <Button @click.stop="onClickDeleteRole(row)"
                          type="text"
                          class="tableOperaBtn"
                          v-hasPermi="['/admin/userArchives/delete']">删除</Button>
                </template>
              </template>
            </Table>
          </template>
          <Page class="m-t-10 m-l-10"
                :total="totalPage"
                :current="pageNum"
                :page-size="pageSize"
                show-total
                show-sizer
                show-elevator
                @on-change="onPageChange"
                @on-page-size-change="onPageSizeChange" />
        </div>
      </div>
    </div>

    <!-- 新增 / 修改用户 -->
    <UserModal :data.sync="userData"
               v-model="userVisible"
               @onClickConfirm="getList" @onChange='onChangClearRowStyle'></UserModal>

    <!-- 添加机构 -->
    <DepartmentModal v-model="departmentVisible" @onChange='onChangClearRowStyle'></DepartmentModal>

    <!-- 机构详情 -->
    <UserDepartmentDetailModal :dataId.sync="userDepartmentDetailId"
                               v-model="userDepartmentDetailVisible"></UserDepartmentDetailModal>

    <!-- 添加角色 -->
    <RoleModal :dataId.sync="roleDataId"
               v-model="roleVisible"
               @onClickConfirm="getList" @onChange='onChangClearRowStyle'></RoleModal>
  </div>
</template>

<script>
import MyTreeSelect from '@/components/common/MyTreeSelect';
import UserModal from '@/components/product/admin/modal/systemSetting/UserModal'
import MyTree from '@/components/common/MyTree';
import DepartmentModal from '@/components/product/admin/modal/systemSetting/DepartmentModal'
import UserDepartmentDetailModal from '@/components/product/admin/modal/systemSetting/UserDepartmentDetailModal'
import RoleModal from '@/components/product/admin/modal/systemSetting/RoleModal'
import { checkPermi } from '@/utils/permission'
import { mapActions, mapGetters } from 'vuex';
export default {
  components: {
    MyTreeSelect,
    MyTree,
    UserModal,
    DepartmentModal,
    UserDepartmentDetailModal,
    RoleModal
  },
  data () {
    return {
      currentTabIndex: 0,
      tabArr: [
        {
          name: '用户列表'
        },
        {
          name: '角色管理'
        }
      ],
      role: '',
      roleArr: [],
      name: '',
      treeData: [],
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableWidths: 0,
      tableWidth: 0,
      tableHeight: 0,
      tableLoading: false,
      columnsUser: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '序号',
          width: 45,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '姓名',
          key: 'name',
          tooltip: true,
          width: 90,
          fixed: 'left',
          align: 'center'
        },
        {
          title: '所属机构',
          key: 'organization',
          tooltip: true,
          width: 180,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            if (row.depart) {
              return h('span', row.depart.departName)
            } else {
              return h('span')
            }
          }
        },
        {
          title: '用户账号',
          key: 'account',
          tooltip: true,
          width: 100,
          align: 'center'
        },
        {
          title: '角色',
          key: 'role',
          tooltip: true,
          width: 90,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            let text = []
            if (row.roles) {
              row.roles.forEach(item => {
                text.push(item.roleName)
              });
              return h('span', text.join())
            }
          }
        },
        {
          title: '联系电话',
          key: 'phone',
          tooltip: true,
          width: 100,
          align: 'center'
        },
        {
          title: '登录平台',
          key: 'loginTerrace',
          tooltip: true,
          width: 80,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            let text
            switch (row.platform) {
              case 0:
                text = '全部'
                break;
              case 1:
                text = 'web'
                break;
              case 2:
                text = 'app'
                break;
              default:
                break;
            }
            return h('span', text)
          }
        },
        {
          title: '备注',
          key: 'remark',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 180,
          align: 'center'
        }
      ],
      columnsRole: [
        {
          type: 'selection',
          width: 45,
          fixed: 'left',
          align: 'center'
        }, {
          title: '序号',
          width: 60,
          align: 'center',
          fixed: 'left',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '角色名称',
          key: 'roleName',
          tooltip: true,
          fixed: 'left',
          width: 200,
          align: 'center'
        },
        {
          title: '管理员',
          key: 'roleType',
          tooltip: true,
          width: 100,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', row.roleType == 1 ? '是' : '否')
          }
        },
        {
          title: '绑定人数',
          key: 'bindUserCount',
          tooltip: true,
          width: 140,
          align: 'center'
        },
        {
          title: '角色是否停用',
          key: 'status',
          tooltip: true,
          width: 120,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            return h('span', row.status == 0 ? '启用' : '禁用')
          }
        },
        {
          title: '登录平台',
          key: 'loginTerrace',
          tooltip: true,
          width: 80,
          align: 'center',
          render: (h, params) => {
            let row = params.row
            let text
            switch (row.platform) {
              case 0:
                text = '全部'
                break;
              case 1:
                text = 'web'
                break;
              case 2:
                text = 'app'
                break;
              default:
                break;
            }
            return h('span', text)
          }
        },
        {
          title: '角色描述',
          key: 'description',
          tooltip: true,
          minWidth: 120,
          align: 'center'
        },
        {
          title: '操作',
          slot: 'operation',
          width: 140,
          align: 'center'
        }
      ],
      data: [],
      selectArr: [],
      selectDataArr: [],
      selectIndexArr: [],
      selectVisitedArr: [],
      selectVisitedIndexArr: [],
      account: '',
      accountId: '',
      departId: '',
      organizationAll: '',
      roleName: '',
      // 添加用户
      userVisible: false,
      userData: {},
      // 添加机构
      departmentVisible: false,
      // 机构详情
      userDepartmentDetailVisible: false,
      userDepartmentDetailId: '',
      // 添加角色
      roleVisible: false,
      roleDataId: ''
    };
  },
  watch: {
    currentTabIndex (newVal) {
      this.resetSearchData()
      this.onClickSearch()
      // if (newVal == 0) {
      //   this.getRoleAllList()
      // }
    },
    selectDataArr (newVal) {
      this.selectIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          if (this.currentTabIndex === 0) {
            let data = this.data.find((items, index) => {
              return item.uid == items.uid
            })
            this.selectIndexArr.push(data.uid)
          } else {
            let data = this.data.find((items, index) => {
              return item.id == items.id
            })
            this.selectIndexArr.push(data.id)
          }
        })
      }
    },
    selectVisitedArr (newVal) {
      console.log(newVal);
      this.selectVisitedIndexArr = []
      if (newVal.length > 0) {
        newVal.forEach(item => {
          if (this.currentTabIndex === 0) {
            let data = this.data.find((items, index) => {
              return item.uid == items.uid
            })
            this.selectVisitedIndexArr.push(data.uid)
          } else {
            let data = this.data.find((items, index) => {
              return item.id == items.id
            })
            this.selectVisitedIndexArr.push(data.id)
          }
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      'departmentTreeArr': 'getDepartmentTreeList',
      'accountArr': 'getUserTreeList',
      'userIsAdmin': 'getUserIsAdmin',
    }),
    getColumns () {
      let columns = []
      switch (this.currentTabIndex) {
        case 0:
          columns = this.columnsUser
          break;
        case 1:
          columns = this.columnsRole
          break;
        default:
          break;
      }
      return columns
    }
  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.onresize = null
  },
  methods: {
    checkPermi,
    ...mapActions([
      'updateUserTreeList',
      'updateDepartmentTreeList'
    ]),
    init () {
      this.tableWidths = window.innerWidth - 240
      this.tableWidth = window.innerWidth - 490
      this.tableHeight = window.innerHeight - 350
      window.onresize = () => {
        this.tableWidths = window.innerWidth - 240
        this.tableWidth = window.innerWidth - 490
        this.tableHeight = window.innerHeight - 350
      }
      this.updateUserTreeList()
      this.updateDepartmentTreeList()
      // 1超级管理员 2不是超级管理员
      if (this.userIsAdmin == 1) {
        this.tabArr = [
          {
            name: '用户列表'
          },
          {
            name: '角色管理'
          }
        ]
      } else {
        this.tabArr = [
          {
            name: '用户列表'
          }
        ]
      }
      this.getRoleAllList()
      this.getList()
    },
    // 获取角色下拉框列表
    getRoleAllList () {
      let params = {
        page: 1,
        size: 99999
      }
      this.$http.getRoleList(params).then(res => {
        if (res.code === 200) {
          this.roleArr = res.result.rolelist.map(item => {
            item.name = item.roleName
            return item
          })
          this.roleArr.unshift({ id: 0, name: '全部' })
        }
      })
    },
    onClickSet () {

    },
    onChangeDate (date) {
      this.date = date
    },
    onClickBatch (name) {
      switch (name) {
        case 'handle':
          console.log('handle');
          break;
        default:
          break;
      }
    },
    onChangeSelect (selection) {
      this.selectDataArr = selection
      switch (this.currentTabIndex) {
        case 0:
          this.selectArr = selection.map(item => item.uid)
          break;
        case 1:
          this.selectArr = selection.map(item => item.id)
          break;
        default:
          break;
      }
    },
    onChangeSort (column, key, order) {

    },
    rowClassName (row, index) {
      if (this.currentTabIndex === 0) {
        if (this.selectVisitedIndexArr.includes(row.uid)) {
          return 'table-bgcolor'
        }

      } else {
        if (this.selectVisitedIndexArr.includes(row.id)) {
          return 'table-bgcolor'
        }
      }
      if (this.currentTabIndex === 0) {
        if (this.selectIndexArr.includes(row.uid)) {
          return 'table-bgcolor'
        }

      } else {
        if (this.selectIndexArr.includes(row.id)) {
          return 'table-bgcolor'
        }
      }
    },
    // 点击机构操作的下拉菜单
    onClickDropdown (value) {
      if (value == 'detail') {
        if (this.departId) {
          this.userDepartmentDetailVisible = true
          this.userDepartmentDetailId = this.departId
        } else {
          this.$Message.info('请选择机构');
        }
      }
    },
    // 用户账号
    onChangeAccount (section) {
      if (section.type != 'depart') {
        this.account = section.name
        this.accountId = section.id
      }
    },
    // 选择部门
    onSelectChangeDepart (section, data) {
      this.departId = data.id
      this.onClickSearch()
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    // 查询
    onClickSearch () {
      this.pageNum = 1
      this.getList()
    },
    // 点击重置
    onClickReset () {
      this.resetSearchData()
      this.onClickSearch()
    },
    // 重置搜索条件
    resetSearchData () {
      this.name = ''
      this.account = ''
      this.accountId = ''
      this.role = ''
      this.departId = ''
      this.organizationAll = ''
      // 角色
      this.roleName = ''
    },
    onChangClearRowStyle () {
      this.selectVisitedArr = []
      
    },
    getList () {
      this.selectIndexArr = []
      switch (this.currentTabIndex) {
        case 0:
          this.getUserList()
          break;
        case 1:
          this.getRoleList()
          break;
        default:
          break;
      }
    },
    // 获取用户列表
    getUserList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        name: this.name,
        roleids: this.role === 0 ? '' : this.role,
        depid: this.departId,
        uid: this.accountId,
        account: this.account,
        deptcascade: this.organizationAll ? 1 : 0
      }
      this.tableLoading = true
      this.$http.getUserList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.users
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 修改用户
    onClickEditUser (row) {
      this.selectVisitedArr.push(row)
      this.userData = row
      this.userVisible = true
    },
    // 删除用户
    onClickDeleteUser (row) {
      this.selectVisitedArr.push(row)
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            uid: row.uid
          }
          this.$http.deleteUser(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              this.onChangClearRowStyle()
              this.getList()
              // this.$store.dispatch('updateCarTypeAllList', true)
            }
          })
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 重置密码
    onClickUserResetPwd (row) {
      this.selectVisitedArr.push(row)
      this.$Modal.confirm({
        title: '重置',
        content: '确认重置?',
        onOk: () => {
          let params = {
            uid: row.uid,
            password: '123456'
          }
          this.$store.dispatch('createOrEditUser', params).then(res => {
            this.getList()
          }).catch(err => {
            this.$Message.info(err.message)
          })
          this.onChangClearRowStyle()
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 获取角色列表
    getRoleList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        rolename: this.roleName,
        rolecode: '',
      }
      this.tableLoading = true
      this.$http.getRoleList(params).then(res => {
        this.tableLoading = false
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.data = res.result.rolelist
          if (this.pageNum == 1) {
            let a = {}
            this.data.forEach(item => {
              if (item.id == '00000000') {
                a = item
              }
            })
            let index = this.data.findIndex(item => {
              return item.id == a.id
            })
            this.data.splice(index, 1)
            this.data.unshift(a)
          }
        }
      }).catch(err => {
        this.tableLoading = false
      })
    },
    // 修改角色
    onClickEditRole (row) {
      this.selectVisitedArr.push(row)
      this.roleDataId = row.id
      this.roleVisible = true
    },
    // 删除角色
    onClickDeleteRole (row) {
      this.selectVisitedArr.push(row)
      this.$Modal.confirm({
        title: '删除',
        content: '确认删除?',
        onOk: () => {
          let params = {
            id: row.id
          }
          this.$http.deleteRole(params).then(res => {
            this.$Message.info(res.message)
            if (res.code === 200) {
              this.onChangClearRowStyle()
              this.getList()
            }
          })
        },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
      })
    },
    // 修改角色状态
    onClickStatusRole (row) {
      this.selectVisitedArr.push(row)
      if (row.status == 0) {
        this.$Modal.confirm({
          title: '停用',
          content: '确认停用?',
          onOk: () => {
            let params = {
              id: row.id,
              status: 1
            }
            this.$store.dispatch('editRoleStatus', params).then(res => {
              this.getList()
            }).catch(err => {
              this.$Message.info(err.message)
            })
            this.onChangClearRowStyle()
          },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
        })
      } else {
        this.$Modal.confirm({
          title: '恢复',
          content: '确定恢复?',
          onOk: () => {
            let params = {
              id: row.id,
              status: 0
            }
            this.$store.dispatch('editRoleStatus', params).then(res => {
              this.getList()
            }).catch(err => {
              this.$Message.info(err.message)
            })
            this.onChangClearRowStyle()
          },
        onCancel: () => {
          this.onChangClearRowStyle()
        },
        })
      }
    },
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";
::v-deep {
  .ivu-table .table-bgcolor td {
    background: #ebf7ff !important;
    border-right: 1px solid #ebf7ff;
  }
  .ivu-table .table-visited-bgcolor td {
    background: rgb(235, 247, 255) !important;
    border-right: 1px solid rgb(235, 247, 255);
  }
}
.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;

  .topbar {
    background-color: #fff;

    .topbar-search {
      padding: 16px;
    }
  }

  .content {
    padding: 16px;
    background-color: #fff;
    margin-top: 16px;

    .tree {
      margin-top: 16px;
      width: 230px;
      margin-right: 16px;
      overflow-y: auto;
      flex-shrink: 0;
      border: 1px solid #e8eaec;
    }

    .table {
      margin-top: 16px;
      flex: 1;
    }
  }
}
</style>
