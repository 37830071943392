<template>
  <Modal :value="visible"
         :title="dataId ? '修改' + title : '新增' + title"
         @on-visible-change="onChangeVisible"
         width="600"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li">
          <p class="li-title">
            <span class="li-must">*</span>
            角色名称：
          </p>
          <Input v-model="roleName"
                 maxlength="30"
                 v-stringLimit
                 placeholder="角色名称"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">登录平台：</p>
          <Select v-model="platform"
                  placeholder="登录平台"
                  clearable>
            <Option :value="item.id"
                    v-for="item in platformList"
                    :key="item.id">{{item.name}}</Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">
            管理员：
          </p>
          <Checkbox class="form-ul-li-checkbox"
                    v-model="roleType"></Checkbox>
        </li>
        <li class="form-ul-li">
          <p class="li-title">
            <span class="li-must">*</span>
            角色描述：
          </p>
          <Input v-model="description"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="角色描述" />
        </li>
      </ul>
    </div>
    <Tabs v-model="currentTabIndex"
          class="tabs">
      <TabPane v-for="item in tabArr"
               :key="item.name"
               :label="item.name"></TabPane>
    </Tabs>
    <div v-show="currentTabIndex == 0"
         class="permission">
      <MyTree ref="myTree"
              :data="operationTreeData"
              style="padding:0 10px"
              show-checkbox
              @on-check-change="onCheckChangeOperation"></MyTree>
    </div>
    <div v-show="currentTabIndex == 1"
         class="permission">
      <RadioGroup v-model="dataPermission"
                  vertical>
        <Radio v-for="(item,index) in dataPermissionArr"
               :key="index"
               :label="item.id">
          <div class="radio-div">
            <p>{{item.name}}</p>
            <span>{{item.desc}}</span>
          </div>
        </Radio>
      </RadioGroup>
      <MyTree ref="myDepartTree"
              :data="dataTreeData"
              style="padding:0 10px,height:200px;"
              show-checkbox
              v-show="dataPermission == 4"
              @on-check-change="onCheckChangeData"></MyTree>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
import MyTree from '@/components/common/MyTree';
import {
  mapActions,
  mapGetters
} from 'vuex';
export default {
  components: {
    MyTree
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    title: {
      type: String,
      default: '角色'
    },
    visible: Boolean,
    dataId: String
  },
  data () {
    return {
      loading: false,
      roleName: '', // 角色名称
      description: '', // 角色描述
      roleType: false,  // 是否管理员
      platform: '',
      sysRoleMenu: {}, // 操作权限
      dataPermission: '', // 数据权限
      deptvalues: '', //  数据权限的部门ids
      currentTabIndex: 0,
      platformList: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: 'web'
        },
        {
          id: 2,
          name: 'app'
        },
      ],
      tabArr: [{
        name: '操作权限'
      },
      {
        name: '数据权限'
      }
      ],
      operationTreeData: [],
      dataPermissionArr: [{
        id: 1,
        name: '个人',
        desc: '只能操作自己的数据(指派、抄送、上报等)'
      }, {
        id: 2,
        name: '所属机构',
        desc: '能操作自己直属机构的数据'
      }, {
        id: 3,
        name: '所属机构及下属机构',
        desc: '直属机构及下属机构'
      }, {
        id: 5,
        name: '全系统',
        desc: '能操作全系统的数据'
      }, {
        id: 4,
        name: '自定义配置',
        desc: '自己配置角色可操作的数据权限'
      },],
      dataTreeData: []
    }
  },
  watch: {
    dataId (newValue) {
      newValue && this.getDetail()
    },
    menuTreeData: {
      deep: true,
      handler () {
        this.resetTreeData()
      }
    },
    departTreeData: {
      deep: true,
      handler () {
        this.resetdepartTreeData()
      }
    }
  },
  computed: {
    ...mapGetters({
      'menuTreeData': 'getMenuTreeList',
      'departTreeData': 'getDepartmentTreeList'
    })
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      'updateMenuTreeList',
      'updateDepartmentTreeList'
    ]),
    init () {
      this.updateMenuTreeList() // 每次打开重新获取操作权限树的数据，清空上次选中的数据
      this.updateDepartmentTreeList() // 每次打开重新获取数据权限中部门树的数据，清空上次选中的数据
    },
    getDetail () {
      this.$store.dispatch('getRoleDetail', this.dataId).then((res) => {
        for (const i in res) {
          this[i] = res[i]
        }
        this.roleType = res.roleType == 1 ? true : false

        // 数据权限单选组渲染
        this.dataPermission = parseInt(res.permissionRoleId)

        // 操作权限的树渲染
        let permission = res.sysRoleMenu
        this.operationTreeData.forEach(item => {
          let boo = permission.some(p => {
            return p.menuid === item.id && !p.buttonid
          })
          // 1级
          if (boo) {
            item.checked = true
          } else if (item.children) {
            // 2级
            item.children.forEach(items => {
              if (items.subjectType == 2) {
                let boo = permission.some(p => p.buttonid == items.id)
                if (boo) {
                  items.checked = true
                }
              } else {
                let boo = permission.some(p => !p.buttonid && p.menuid == items.id)
                if (boo) {
                  items.checked = true
                } else if (items.children) {
                  // 3级
                  items.children.forEach(itemss => {
                    if (itemss.subjectType == 2) {
                      let boo = permission.some(p => p.buttonid == itemss.id)
                      if (boo) {
                        itemss.checked = true
                      }
                    } else {
                      let boo = permission.some(p => {
                        return p.menuid === itemss.id && !p.buttonid
                      })
                      if (boo) {
                        itemss.checked = true
                      } else if (itemss.children) {
                        // 4级
                        itemss.children.forEach(itemsss => {
                          if (itemsss.subjectType == 2) {
                            let boo = permission.some(p => p.buttonid == itemsss.id)
                            if (boo) {
                              itemsss.checked = true
                            }
                          } else {
                            let boo = permission.some(p => p.menuid === itemsss.id && !p.buttonid)
                            if (boo) {
                              itemsss.checked = true
                            }
                          }
                        })
                      }
                    }
                  })
                }
              }
            })
          }
        })
        this.$refs.myTree.resetTreeData()

        // 数据权限的部门树渲染
        if (res.permissionRoleId == 4) {
          let deptvalues = res.deptvalues.split(',')
          const getDepartIds = (list) => {
            list.forEach((item) => {
              if (item.children) {
                let boo = deptvalues.some(p => p === item.id)
                if (boo) {
                  item.checked = true
                }
                getDepartIds(item.children)
              } else {
                let boo = deptvalues.some(p => p === item.id)
                if (boo) {
                  item.checked = true
                }
              }
            })
          }
          getDepartIds(this.dataTreeData)
          this.$refs.myDepartTree.resetTreeData()
        }
      })
    },
    // 操作权限
    onCheckChangeOperation (section, data) {
      console.log(section);
      this.sysRoleMenu = section.map(item => {
        let obj = {}
        // subjectType 1菜单 2按钮
        if (item.subjectType == 1) {
          obj = {
            menuid: item.id,
            buttonid: null
          }
        } else {
          obj = {
            menuid: item.parentid,
            buttonid: item.id
          }
        }
        return obj
      })
    },
    // 数据权限
    onCheckChangeData (section, data) {
      let arr = section.map(item => {
        return item.id
      })
      this.deptvalues = arr.join()
    },
    onClickConfirm () {
      if (!this.roleName) return this.$Message.info('请输入角色名称');
      if (!this.description) return this.$Message.info('请输入角色描述')
      let permissionRoleId
      switch (this.dataPermission) {
        case 1:
        case 2:
        case 3:
        case 5:
          permissionRoleId = this.dataPermission
          break;
        case 4:
          permissionRoleId = this.deptvalues
          break;
        default:
          break;
      }
      let params = {
        roleName: this.roleName,
        description: this.description,
        roleType: this.roleType ? 1 : 2,
        platform: this.platform,
        sysRoleMenu: this.sysRoleMenu,
        permissionRoleId: permissionRoleId,
        status: 0,
        dataScope: this.dataPermission
      }
      this.loading = true
      if (this.dataId) params.id = this.dataId
      this.$store.dispatch('createOrEditRole', params).then(res => {
        this.$emit('onClickConfirm', params)
        this.$emit('onChange', false)
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
      }
    },
    resetTreeData () {
      this.operationTreeData = JSON.parse(JSON.stringify(this.menuTreeData))
      this.$refs.myTree.resetTreeData()
    },
    resetdepartTreeData () {
      this.dataTreeData = JSON.parse(JSON.stringify(this.departTreeData))
      this.$refs.myDepartTree.resetTreeData()
    },
    resetData () {
      this.roleName = '' // 角色名称
      this.description = '' // 角色描述
      this.roleType = false
      this.platform = ''
      this.sysRoleMenu = {} // 操作权限
      this.dataPermission = '' // 数据权限
      this.deptvalues = [] // 数据权限的部门ids
      this.currentTabIndex = 0
      this.resetTreeData()
      this.resetdepartTreeData()
    }
  }
}
</script>

<style lang="scss" scoped>
.radio-div {
  display: inline-block;

  p {
    display: inline-block;
    min-width: 150px;
  }

  span {
    color: #c5c5c5;
  }
}

.permission {
  height: 300px;
  overflow: auto;
}
</style>
