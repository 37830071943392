<template>
  <Modal :value="visible"
         :title="data.uid ? '修改' + title : '新增' + title"
         @on-visible-change="onChangeVisible"
         width="600"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>用户账号：</p>
          <Input v-model="account"
                 :disabled="data.uid ? true : false"
                 maxlength="30"
                 v-accountLimit
                 placeholder="用户账号"></Input>
        </li>
        <li class="form-ul-li half"
            v-if="!data.uid">
          <p class="li-title"><span class="li-must">*</span>密码：</p>
          <Input v-model="password"
                 maxlength="30"
                 type="password"
                 placeholder="密码"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>姓名：</p>
          <Input v-model="name"
                 maxlength="30"
                 v-stringLimit
                 clearable
                 placeholder="姓名"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>所属机构：</p>
          <MyTreeSelect :value="department"
                        placeholder="所属机构"
                        :data="departmentArr"
                        search
                        @onSelectChange="onChangeDepartment">
          </MyTreeSelect>
        </li>
        <!-- <li class="form-ul-li half">
          <p class="li-title">登录平台：</p>
          <Select v-model="platform"
                  placeholder="登录平台"
                  clearable>
            <Option :value="item.id"
                    v-for="item in platformList"
                    :key="item.id">{{item.name}}</Option>
          </Select>
        </li> -->
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>联系电话：</p>
          <Input v-model="phone"
                 maxlength="11"
                 v-intLimit
                 clearable
                 placeholder="联系电话"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">机构主管人：</p>
          <Checkbox v-model="isDepAdmin"
                    class="form-ul-li-checkbox"></Checkbox>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">巡查人员：</p>
          <MyTreeSelect :value="patrol"
                        placeholder="巡查人员"
                        :data="patrolArr"
                        search
                        clearable
                        @onSelectChange="onChangePatrol">
          </MyTreeSelect>
        </li>
        <li class="form-ul-li">
          <p class="li-title"><span class="li-must">*</span>角色：</p>
          <Select v-model="roleids"
                  multiple
                  placeholder="角色">
            <Option :value="item.id"
                    v-for="item in roleArr"
                    :key="item.id">{{item.name}}</Option>
          </Select>
        </li>
        <li class="form-ul-li">
          <p class="li-title"><span class="li-must">*</span>头像：</p>
          <ul class="file-img">
            <UploadFile @uploadSuccess="uploadPicture"
                        :disabled="picture"
                        accept=".doc,image/*">
              <li>
                <el-image :src="picture"
                          fit="cover"
                          :preview-src-list="[picture]"
                          class="w-full h-full"
                          v-show="picture && getFileType(picture) == 1">
                </el-image>
                <img src="../../../../../assets/common/word.png"
                     v-show="picture && getFileType(picture) == 2"
                     @click.stop="onClickDownFile(picture)">
                <Icon type="md-close-circle"
                      size="24"
                      color="#fa5357"
                      class="file-img-deleteIcon"
                      @click.stop="onClickDeletePicture"
                      v-show="picture" />
                <Icon custom="i-icon icon-tianjia1"
                      size="40"
                      color="#B5B5B5"
                      v-show="!picture" />
              </li>
            </UploadFile>
          </ul>
        </li>
        <li class="form-ul-li">
          <p class="li-title"></p>
          <div class="file-tip">
            支持格式为png、jpg、jpeg、doc，最大不能超过20M；
          </div>
        </li>
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
import UploadFile from '@/components/common/UploadFile'
import { mapActions, mapGetters } from 'vuex'
import MyTreeSelect from '../../../../common/MyTreeSelect.vue'
export default {
  components: {
    UploadFile,
    MyTreeSelect
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    title: {
      type: String,
      default: '用户'
    },
    visible: Boolean,
    data: Object
  },
  data () {
    return {
      loading: false,
      account: '',
      password: '',
      name: '',
      department: '',
      depid: '',
      platform: '',
      phone: '',
      userphoto: '',
      isDepAdmin: false,
      patrol: '',
      patrolUid: '',
      roleids: [],
      picture: '',
      remark: '',
      roleArr: [],
      platformList: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: 'web'
        },
        {
          id: 2,
          name: 'app'
        },
      ],
      // roleidsList: []
    }
  },
  watch: {
    data (newVal) {
      if (newVal.uid) {
        for (const i in newVal) {
          this[i] = newVal[i]
          this.patrol = newVal.patrol.name
          this.patrolUid = newVal.patrol.uid
          this.department = newVal.depart.departName
          this.depid = newVal.depart.id
          
          this.picture = newVal.userphoto
          this.isDepAdmin = newVal.isDepAdmin == 1 ? true : false
          this.roleids = newVal.roles.map(item => {
            return item.id
          });
        }
        console.log(newVal.userphoto);
      }
    }
  },
  computed: {
    ...mapGetters({
      'departmentArr': 'getDepartmentTreeList',
      'patrolArr': 'getUserTreeList'
    }),
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      'updateDepartmentTreeList',
      'updateUserTreeList'
    ]),
    init () {
      this.updateDepartmentTreeList()
      this.updateUserTreeList()
      this.getRoleList()
    },
    // 获取角色列表
    getRoleList () {
      this.$http.getRoleAllList().then(res => {
        if (res.code === 200) {
          this.roleArr = res.result.map(item => {
            item.name = item.roleName
            return item
          })
        }
      })
    },
    getFileType (file) {
      let filePath = file
      let index = filePath.lastIndexOf(".");
      let type = filePath.substr(index + 1);
      let imgArr = ['tif', 'pjp', 'webp', 'xbm', 'jxl', 'svgz', 'jpg', 'jpeg', 'ico', 'tiff', 'gif', 'svg', 'bmp', 'png', 'jfif', 'pjpeg', 'avif']
      if (imgArr.includes(type)) {
        return 1
      } else {
        return 2
      }
    },
    // 点击下载文件
    onClickDownFile (file) {
      window.open(file)
    },
    // 修改机构
    onChangeDepartment (data) {
      this.depid = data.id
      this.department = data.name
    },
    // 上传照片
    uploadPicture (arr) {
      if (arr.length > 0) {
        this.picture = arr[0].url
      }
    },
    // 删除图片
    onClickDeletePicture () {
      this.picture = ''
    },
    // 巡查人员
    onChangePatrol (data) {
      if (data.type != 'depart') {
        this.patrolUid = data.id
        this.patrol = data.name
      }
    },
    onClickConfirm () {
      if (!this.account) return this.$Message.info('请输入用户账号');
      if (!this.data.uid) {
        if (!this.password) return this.$Message.info('请输入密码')
      }
      if (!this.name) return this.$Message.info('请输入姓名')
      if (!this.depid) return this.$Message.info('请选择所属机构')
      if (!this.phone) return this.$Message.info('请输入联系电话')
      if (this.roleids.length == 0) return this.$Message.info('请选择角色')
      if (!this.picture) return this.$Message.info('请上传头像')
      let params = {
        account: this.account,
        name: this.name,
        depid: this.depid,
        // platform: this.platform,
        phone: this.phone,
        isDepAdmin: this.isDepAdmin ? 1 : 2,
        patrolUid: this.patrolUid,
        roleids: this.roleids.join(),
        remark: this.remark,
        userphoto: this.picture

      }
      this.loading = true
      console.log(params);
      if (this.data.uid) params.uid = this.data.uid
      if (!this.data.id) params.password = this.password
      this.$store.dispatch('createOrEditUser', params).then(res => {
        this.$emit('onClickConfirm', params)
        this.$emit('onChange', false)
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.$emit('onChange', false)
        this.$emit('update:data', {})
        this.resetData()
      }
    },
    resetData () {
      this.account = ''
      this.password = ''
      this.name = ''
      this.department = ''
      this.depid = ''
      this.platform = ''
      this.phone = ''
      this.isDepAdmin = false
      this.patrol = ''
      this.patrolUid = ''
      this.roleids = []
      this.remark = ''
      this.picture = ''
      this.userphoto = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.form-ul {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  li {
    width: 100%;
  }
  .half {
    width: 48%;
    margin-right: 2%;
  }
}
// .form-ul-li Button {
//   color: rgb(207, 207, 207);
// }
// .form-ul-li {
//       .li-title {
//         min-width: 210px;
//       }
//     }
</style>