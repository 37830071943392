<template>
  <Modal :value="visible"
         :title="title"
         @on-visible-change="onChangeVisible"
         width="600"
         footer-hide
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li half">
          <label>机构名称：</label>
          <span>{{data.departName || '--'}}</span>
        </li>
        <li class="form-ul-li half">
          <label>机构类型：</label>
          <span>{{data.type || '--'}}</span>
        </li>
        <li class="form-ul-li half">
          <label>上级机构：</label>
          <span>{{data.parentName || '--'}}</span>
        </li>
        <!-- <li class="form-ul-li half">
          <label>作业网格：</label>
          <span>{{data.managementNetwork || '--'}}</span>
        </li>
        <li class="form-ul-li half">
          <label>作业面积：</label>
          <span>{{data.departmentName || '--'}}</span>
        </li> -->
        <li class="form-ul-li half">
          <label>责任人：</label>
          <span>{{data.person || '--'}}</span>
        </li>
        <li class="form-ul-li">
          <label>联系电话：</label>
          <span>{{data.phone || '--'}}</span>
        </li>
        <li class="form-ul-li">
          <label>备注：</label>
          <span>{{data.remark || '--'}}</span>
        </li>
      </ul>
    </div>
  </Modal>
</template>

<script>
export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    title: {
      type: String,
      default: '基础信息'
    },
    visible: Boolean,
    dataId: String
  },
  data () {
    return {
      data: {}
    }
  },
  watch: {
    dataId (newVal) {
      newVal && this.getDetail()
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    getDetail () {
      this.$store.dispatch('getDepartDetail', this.dataId).then(res => {
        this.data = res
      })
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.$emit('onChange', false)
        this.$emit('update:data', '')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>